import React, { Fragment, useState, useEffect } from 'react';
import { formatDate } from 'admin_components/admin_helpers';
import { capitalize } from 'admin_components/admin_helpers';
import { xhrConfig } from 'form_components/apis/api';
import axios from 'axios';
const put = axios.put;

const currentPath = () => {
  const { pathname, search, hash } = window.location;
  return [pathname, search, hash]
    .filter((value) => value && value.length > 0)
    .join('');
};

const LoggedInMenu = ({ currentUser }) => {
  const {
    first_name,
    email,
    isAdmin,
    subscribed,
    hasPassword,
    subscriberEmailHash,
    memberSince,
  } = currentUser;

  const [stripeSub, setStripeSub] = useState(currentUser.stripeSubscription);
  const [adminSubFlag, setAdminSubFlag] = useState(
    currentUser.adminSupportingMember,
  );
  const subCanceled = () =>
    stripeSub && stripeSub.cancel_at_period_end && stripeSub.cancel_at;
  const subPaused = () =>
    !subCanceled() &&
    stripeSub &&
    stripeSub.pause_collection_behavior == 'void';
  const supportingMemberStatus = () =>
    adminSubFlag
      ? 'Admin Member'
      : subCanceled()
      ? 'Canceled'
      : subPaused()
      ? 'Paused'
      : stripeSub.status == 'past_due'
      ? 'Past Due'
      : stripeSub && stripeSub.status
      ? capitalize(stripeSub.status)
      : '';
  const supportingMember = () =>
    adminSubFlag ||
    (stripeSub &&
      (stripeSub.status == 'active' || stripeSub.status == 'past_due'));
  const pastSupportingMember = () =>
    stripeSub && stripeSub.status == 'canceled';
  const pauseResumesOn = () =>
    stripeSub.pause_collection_resumes_at
      ? stripeSub.pause_collection_resumes_at * 1000
      : null;
  const cancelOn = () =>
    stripeSub.cancel_at ? stripeSub.cancel_at * 1000 : null;

  const updateSubFromStripe = async () => {
    const result = await put(
      '/account/membership/refresh.json',
      {},
      xhrConfig(),
    );
    setStripeSub(result.data);
  };
  const shouldRefresh = () => {
    if (!stripeSub) {
      return false;
    }
    const lastRefresh = new Date(Date.parse(stripeSub.updated_at));
    const nextRefresh = new Date(
      new Date(lastRefresh).setDate(lastRefresh.getDate() + 28),
    );
    const now = new Date(Date.now());
    const cancelAt = stripeSub.cancel_at
      ? new Date(stripeSub.cancel_at)
      : false;
    const resumeAt = stripeSub.pause_collection_resumes_at
      ? new Date(stripeSub.pause_collection_resumes_at)
      : false;
    const currentPeriodEnd = stripeSub.current_period_end
      ? new Date(stripeSub.current_period_end * 1000)
      : false;
    return (
      stripeSub.status == 'incomplete' ||
      nextRefresh <= now ||
      (cancelAt && cancelAt <= now) ||
      (resumeAt && resumeAt <= now) ||
      (currentPeriodEnd && currentPeriodEnd <= now)
    );
  };
  useEffect(() => {
    if (shouldRefresh()) {
      updateSubFromStripe();
    }
  }, []);

  return (
    <div className="text-grey-darkest relative" style={{ zIndex: '10' }}>
      <div className="pt-8 pb-4 px-8">
        {isAdmin && (
          <a
            href="/new_admin/newsletters"
            className="inline-block mb-2 text-teal-light hover:text-teal no-underline"
          >
            Admin
          </a>
        )}
        <h2 className="accountMenuGreeting">Hello, {first_name}!</h2>
        <p className="mb-6 text-base leading-tight">
          Thanks for being part of the Listings Project community since{' '}
          {formatDate(memberSince)}!
        </p>

        {supportingMember() && (
          <Fragment>
            <p className="accountMenuMembershipLevel">Supporting Membership</p>
            <p className="text-sm mb-1">{supportingMemberStatus()} </p>

            {subPaused() && pauseResumesOn() && (
              <p className="text-sm">
                Resumes on {formatDate(pauseResumesOn())}
              </p>
            )}
            {subCanceled() && cancelOn() && (
              <p className="text-sm">Ends on {formatDate(cancelOn())}</p>
            )}
          </Fragment>
        )}
        {!supportingMember() && (
          <p className="mb-2 text-base">Free Membership</p>
        )}
      </div>
      <div className="w-full px-4 border border-b-0 border-teal"></div>
      <div className="pt-4 px-8">
        <Fragment>
          <h3 className="accountMenuSubheader">Membership</h3>
          <ul className="accountMenuList">
            {!supportingMember() && (
              <Fragment>
                <li className="accountMenuListItem">
                  <a href="/membership" className="accountMenuLink">
                    Upgrade Membership
                  </a>
                </li>
              </Fragment>
            )}
            {pastSupportingMember() && (
              <Fragment>
                <li className="accountMenuListItem">
                  <form method="POST" action="/customer_sessions">
                    <input
                      type="hidden"
                      name="authenticity_token"
                      value={document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute('content')}
                    />{' '}
                    <input
                      type="hidden"
                      name="return_path"
                      value={currentPath()}
                    />
                    <button
                      className="accountMenuLink"
                      style={{ width: 'auto' }}
                      type="submit"
                    >
                      Billing History
                    </button>
                  </form>
                </li>
              </Fragment>
            )}

            {supportingMember() && (
              <Fragment>
                <li className="accountMenuListItem">
                  <a href="/account/membership" className="accountMenuLink">
                    Membership Benefits
                  </a>
                </li>
              </Fragment>
            )}
            {supportingMember() && (
              <Fragment>
                <li className="accountMenuListItem">
                  <form method="POST" action="/customer_sessions">
                    <input
                      type="hidden"
                      name="authenticity_token"
                      value={document
                        .querySelector('meta[name="csrf-token"]')
                        .getAttribute('content')}
                    />
                    <input
                      type="hidden"
                      name="return_path"
                      value={currentPath()}
                    />
                    <button
                      className="accountMenuLink"
                      style={{ width: 'auto' }}
                      type="submit"
                    >
                      Billing Center
                    </button>{' '}
                  </form>
                </li>
              </Fragment>
            )}
            <li className="accountMenuListItem">
              <a
                href="/account/membership/partners"
                className="accountMenuLink"
              >
                Partner Discounts
              </a>
            </li>
          </ul>
        </Fragment>

        <h3 className="accountMenuSubheader">Settings</h3>
        <ul className="accountMenuList">
          <li className="accountMenuListItem">
            <a href="/account/edit" className="accountMenuLink">
              Personal Information
            </a>
          </li>
          <li className="accountMenuListItem">
            {hasPassword && (
              <a href="/account/password" className="accountMenuLink">
                Change Password
              </a>
            )}
            {!hasPassword && (
              <Fragment>
                <a href="/account/password" className="accountMenuLink">
                  Set Password
                </a>
                <br />
                <span className="text-xs italic">
                  Skip the email link at sign in
                </span>
              </Fragment>
            )}
          </li>

          <li className="accountMenuListItem">
            {subscribed && (
              <a
                href={`/subscriber/preferences?email=${email}&h=${subscriberEmailHash}`}
                className="accountMenuLink"
              >
                Newsletter Preferences
              </a>
            )}
            {!subscribed && (
              <a href="/subscribe/now" className="accountMenuLink">
                Sign up for the weekly email newsletter
              </a>
            )}
          </li>
        </ul>
      </div>
      <div className="text-lg px-8 pb-8">
        <a href="/signout" className="accountMenuLink">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export default LoggedInMenu;
