import React, { Fragment } from 'react'
import Cookies from 'js-cookie'

const AcknowledgeButton = () => {
  const handleAcknowledge = () => {
    Cookies.set('acknowleged_cookies', 'yes', { expires: 365 })
    location.reload()
  }
  return (
    <button
      className='acknowledgeButton bg-teal hover:bg-teal-light focus:outline-none focus:border-teal-lightest text-white rounded-lg py-1 px-4'
      onClick={handleAcknowledge}>
      Ok, got it
    </button>
  )
}

export default AcknowledgeButton
